import classNames from "classnames";
import { Link } from "gatsby";
import React from "react";
import { styles } from "../../utilities/styles";

const PrimaryTextButton = ({ children, className, to }) => {
  return (
    <Link
      to={to}
      className={classNames(
        "font-normal text-base px-6 py-2 rounded inline-block tracking-wide no-underline shadow transition-colors",
        styles.button.default,
        className
      )}
    >
      {children}
    </Link>
  );
};
export default PrimaryTextButton;

// bg-green-darkest
// bg-yellow-darker
// bg-yellow-darkest
// bg-teal-darkest
