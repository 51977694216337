import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { styles } from "../utilities/styles";

const componentStyles = {
  headingColored: {
    Mountainland: "text-yellow-dark",
    MountainlandDark: "text-yellow-darkest",
  },
};

export const OurProcess = () => {
  return (
    <>
      <h2 className={classNames(styles.h2, "text-center")}>
        Our{" "}
        <span
          className={
            componentStyles.headingColored[process.env.COMPANY_NAME_SHORTER]
          }
        >
          Process
        </span>
      </h2>
      {/* <hr className="my-8 max-w-lg mx-auto border border-dashed" /> */}
      <div className="grid lg:grid-cols-3 gap-8 mt-8">
        <div className="flex flex-col items-center gap-6">
          <div className="flex justify-center">
            <span className="flex justify-center items-center w-14 h-14 text-center font-light text-2xl border border-dashed rounded-full">
              1
            </span>
          </div>
          <StaticImage
            className="w-full h-64"
            src="../images/home-page/installation-measure.jpg"
            alt="Measuring a window well for a cover"
            objectFit="cover"
            objectPosition="right bottom"
          />
          <div className="p-2">
            <h3 className="text-default text-lg md:text-xl bold mb-2 text-grey-darkest">
              We{" "}
              <span
                className={
                  componentStyles.headingColored[
                    process.env.COMPANY_NAME_SHORTER
                  ]
                }
              >
                measure
              </span>{" "}
              your window well
            </h3>
            <p>
              Our technician will visit your location, equipped with precise
              measuring tools and equipment, to accurately measure your window
              wells.
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center gap-6">
          <div className="flex justify-center">
            <span className="flex justify-center items-center w-14 h-14 text-center font-light text-2xl border border-dashed rounded-full">
              2
            </span>
          </div>
          <StaticImage
            className="w-full h-64"
            src="../images/head-down-welding.jpg"
            alt="Fabricating a window well cover"
            objectFit="cover"
            objectPosition="center center"
          />
          <div className="p-2">
            <h3 className="text-default text-lg md:text-xl bold mb-2 text-grey-darkest">
              We{" "}
              <span
                className={
                  componentStyles.headingColored[
                    process.env.COMPANY_NAME_SHORTER
                  ]
                }
              >
                custom build
              </span>{" "}
              your window well covers
            </h3>
            <p>
              Our production team will custom-build your covers with precise
              measurements and high-quality materials for a perfect, durable
              fit.
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center gap-6">
          <div className="flex justify-center">
            <span className="flex justify-center items-center w-14 h-14 text-center font-light text-2xl border border-dashed rounded-full">
              3
            </span>
          </div>
          <StaticImage
            className="w-full h-64"
            src="../images/installation-trimmed.jpg"
            alt="Installing a window well cover"
            objectFit="cover"
            objectPosition="center center"
          />
          <div className="p-2">
            <h3 className="text-default text-lg md:text-xl bold mb-2 text-grey-darkest">
              We come back to{" "}
              <span
                className={
                  componentStyles.headingColored[
                    process.env.COMPANY_NAME_SHORTER
                  ]
                }
              >
                install
              </span>{" "}
              your window well covers
            </h3>
            <p>
              Our technician will deliver and install your window well covers
              hassle-free.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
