import React from "react";
import IconGuarantee from "../../images/icon-guarantee.png";
import classNames from "classnames";

function Guarantee() {
  return (
    <div className="w-full px-6 py-16 lg:my-12 bg-grey-lighter">
      <div className="max-w-lg mx-auto flex gap-4 items-center justify-center">
        <img
          src={IconGuarantee}
          alt="icon"
          width={100}
          className={classNames("block size-16", "lg:m-8 lg:size-24")}
        />
        <div className="text-lg lg:text-3xl ml-4 font-bold">
          All {process.env.COMPANY_NAME_SHORT} come with a lifetime structural
          guarantee!
        </div>
      </div>
    </div>
  );
}

export default Guarantee;
